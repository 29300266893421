export default function StarsIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.38668 25.1303L8.99999 24L8.61332 25.1303C8.18455 26.4054 7.46642 27.5639 6.51518 28.5152C5.56395 29.4664 4.40542 30.1845 3.13032 30.6133L2 31L3.13032 31.3768C4.40469 31.8069 5.5625 32.5255 6.51355 33.4765C7.4646 34.4276 8.1832 35.5854 8.61332 36.8598L8.99998 38L9.38668 36.8598C9.8168 35.5854 10.5354 34.4276 11.4864 33.4765C12.4375 32.5255 13.5953 31.8069 14.8697 31.3768L16 31L14.8697 30.6133C13.5946 30.1845 12.4361 29.4664 11.4848 28.5152C10.5336 27.5639 9.81545 26.4054 9.38668 25.1303V25.1303Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6629 3.93768L26 2L25.3371 3.93768C24.6021 6.12356 23.371 8.10962 21.7403 9.74032C20.1096 11.371 18.1236 12.6021 15.9377 13.3371L14 14L15.9377 14.6459C18.1223 15.3832 20.1071 16.6151 21.7375 18.2455C23.3679 19.8759 24.5998 21.8607 25.3371 24.0453L26 26L26.6629 24.0453C27.4002 21.8607 28.6321 19.8759 30.2625 18.2455C31.8929 16.6151 33.8777 15.3832 36.0623 14.6459L38 14L36.0623 13.3371C33.8764 12.6021 31.8904 11.371 30.2597 9.74032C28.629 8.10962 27.3979 6.12356 26.6629 3.93768V3.93768Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
