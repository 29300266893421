export default function ContributionIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4355 26.0016C11.3638 24.2363 8.51562 20.181 8.51562 15.4604C8.51562 9.11807 13.6571 3.97656 19.9995 3.97656C26.3419 3.97656 31.4834 9.11807 31.4834 15.4604C31.4834 20.1858 28.6293 24.2446 24.551 26.0071"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6233 18.9453C20.2929 20.8231 23.3754 19.4688 23.3754 17.6706C23.3754 16.5553 22.6101 15.4968 19.9994 15.4968C17.137 15.4968 16.6233 14.5067 16.6233 13.3686C16.6233 12.2305 17.7556 11.0924 20.1462 11.0924C21.4076 11.0924 22.2842 11.5158 23.057 12.4809M19.9994 11.0715V9.0459M19.9994 21.875V19.8494"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.54046 15.5215H1.66992V36.0234H38.3301V15.5215H31.6064"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M7 26.0068H33" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}
