export default function CapIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3256 2.9364L1.51587 13.0211L22.6902 23.1057L38.4999 13.0211L17.3256 2.9364Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4368 17.4579L13.5979 29.9771H34.1679L30.1452 18.399"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7703 12.7612H10.9973C8.42914 12.7612 6.5626 15.2012 7.23464 17.6799L7.66213 19.2566C8.11873 20.9407 6.85054 22.5986 5.10563 22.5986V22.5986C3.3179 22.5986 2.04356 24.3331 2.57792 26.0391L3.81115 29.9762"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2.28586 32.8179C1.89077 31.3892 2.96543 29.9771 4.4477 29.9771V29.9771C5.45622 29.9771 6.34073 30.6502 6.60954 31.6222L8.11431 37.0635H3.45997L2.28586 32.8179Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
