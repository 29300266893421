export default function PrepaidIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.8334" cy="29" r="9" stroke="currentColor" strokeWidth="2" />
      <path
        d="M24.8334 28.6107L27.7599 31.5367L32.8334 26.4633"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M21.1717 24.1805H1.5V5.72998H34.301V21.8574"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.99609 1.5459H38.5V19.5459"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <circle cx="17.9004" cy="14.9552" r="5" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}
