export default function HighlightLeftIcon() {
  return (
    <svg height="100%" viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.0889 22.4893L2.36194 22.6171"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.498 6.85254L18.6632 2.57322"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6631 42.533L28.498 38.2536"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
