export default function DollarIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 37.1429C29.4678 37.1429 37.1429 29.4678 37.1429 20C37.1429 10.5323 29.4678 2.85718 20 2.85718C10.5323 2.85718 2.85718 10.5323 2.85718 20C2.85718 29.4678 10.5323 37.1429 20 37.1429Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2483 25.9377C20.5009 29.1373 25.753 26.8297 25.753 23.7658C25.753 21.8655 24.4489 20.062 20.0006 20.062C15.1237 20.062 14.2483 18.375 14.2483 16.4358C14.2483 14.4967 16.1777 12.5575 20.2508 12.5575C22.4 12.5575 24.4363 13.2789 25.753 14.9233M20.0006 12.522V9.07056M20.0006 30.9295V27.4781"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}
