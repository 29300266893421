import FullWidthContainer from '@components/layout/full-width-container'
import type { FeaturedPlans } from './featured-plans-model'
import { RatingPlanCard } from '@modules/5-cap-ratings/rating-plan-card'
import { LinkButton } from '@components/ui/link-button'
import { CapRating } from '@modules/5-cap-ratings/cap-rating'
import { PlanDetailsLink } from '@components/plan-details-link'
import { ResidentBenefits } from '@modules/5-cap-ratings/resident-benefits'
import { TouchProvider } from '@components/context/touch-provider'
import type { ProcessedImages } from '@lib/image'

export const FeaturedPlansSection = ({
  data,
  processedImages
}: {
  data: FeaturedPlans
  processedImages: ProcessedImages
}) => {
  return (
    <TouchProvider>
      <section className="py-[100px]">
        <FullWidthContainer>
          <h2 className="text-title-md small-desktop:text-title-lg-desktop mb-[40px] small-desktop:mb-[50px]">
            Featured 529 plans
          </h2>
          <div className="hidden small-desktop:block bg-white mb-[70px]" role="table">
            <div role="rowgroup">
              <div
                className="grid grid-cols-[20px_1fr_140px_140px] desktop:grid-cols-[20px_1fr_160px_160px] gap-[20px] pt-[26px] pb-[16px]"
                role="row"
              >
                <div className="text-body-caption" role="columnheader"></div>
                <div className="text-body-caption" role="columnheader">
                  <p>Plan name</p>
                </div>
                <div className="text-body-caption" role="columnheader">
                  <p>Our rating</p>
                </div>
                <div className="text-body-caption" role="columnheader">
                  <p>Resident benefits</p>
                </div>
              </div>
            </div>
            <div role="rowgroup">
              {data.featured_529_plans?.map((plan, idx) => (
                <div className="border-t border-black-80" key={plan.id}>
                  <div
                    className="grid grid-cols-[20px_1fr_140px_140px] desktop:grid-cols-[20px_1fr_160px_160px] gap-[20px] pt-[26px] pb-[16px] items-start"
                    role="row"
                  >
                    <div className="text-body-caption-bold text-blue-500 pt-[6px]" role="cell">
                      <p>0{idx + 1}.</p>
                    </div>
                    <div role="cell">
                      <PlanDetailsLink
                        plan={plan}
                        contextPlacement="table"
                        variant="inline"
                        className="text-title-sm"
                        label={plan.name}
                        designVersion="title_link"
                      />
                    </div>
                    <div role="cell">
                      <CapRating rating={plan.overall_rating} />
                    </div>
                    <div role="cell">
                      <ResidentBenefits
                        level={plan.resident_benefits}
                        hideText={true}
                        processedImages={processedImages}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="small-desktop:hidden mb-[40px]">
            <ul className="flex flex-col gap-[10px]">
              {data.featured_529_plans?.slice(0, 4).map((plan) => (
                <li key={plan.id}>
                  <RatingPlanCard plan={plan} processedImages={processedImages} clickSource="home_page" />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col small-desktop:flex-row gap-x-[20px] gap-y-[10px]">
            <LinkButton
              href="/intro-to-529s/which-is-the-best-529-plan-available"
              variant="outline"
              size="lg"
              className="w-full max-w-[400px] small-desktop:max-w-[260px]"
            >
              Show more plans
            </LinkButton>
            <LinkButton
              href="/article/how-to-choose-a-529-plan"
              variant="ghost"
              size="lg"
              className="w-full max-w-[400px] small-desktop:max-w-[260px]"
            >
              How to choose a 529 plan?
            </LinkButton>
          </div>
        </FullWidthContainer>
      </section>
    </TouchProvider>
  )
}
