export default function ThumbsUpIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4962 18.2923C17.4875 12.9909 21.4054 9.94648 23.9622 7.64291C26.519 5.33933 28.8729 3.48477 30.4892 5.1958C32.1055 6.90684 31.0284 9.10941 29.3374 10.9057C26.6498 13.7607 24.2245 16.1552 24.2245 16.1552C24.2245 16.1552 34.0128 16.1552 36.1656 16.1552C38.8196 16.1552 39.627 20.4887 36.1654 21.8395C36.1654 21.8395 39.5652 25.3128 34.9419 27.4724C34.9419 27.4724 38.1217 30.1102 33.9119 32.5919C33.9119 32.5919 35.0474 33.7998 34.6177 35.423C34.1881 37.0463 32.5726 37.4414 31.1475 37.4415C25.3884 37.4421 18.9346 37.7636 12.4962 33.8366"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.5293"
        y="15.8021"
        width="10.9036"
        height="21.6695"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}
