import FullWidthContainer from '@components/layout/full-width-container'
import { Carousel, CarouselContent, CarouselItem } from '@components/ui/carousel'
import CapIcon from '@components/ui/icons/cap-icon'
import ContributionIcon from '@components/ui/icons/contribution-icon'
import DollarIcon from '@components/ui/icons/dollar-icon'
import PigIcon from '@components/ui/icons/pig-icon'
import PrepaidIcon from '@components/ui/icons/prepaid-icon'
import StarsIcon from '@components/ui/icons/stars-icon'
import ThumbsUpIcon from '@components/ui/icons/thumbs-up-icon'
import { Link } from '@components/ui/link'
import { LinkButton } from '@components/ui/link-button'
import type { ReactElement } from 'react'

interface Tool {
  title: string
  description: string
  icon: ReactElement
  url: string
  isPro?: boolean
}

const tools: Tool[] = [
  {
    title: '529 College Savings Calculator',
    description: 'Get personalized college cost and 529 savings estimates, and calculate monthly contributions.',
    url: '/calculators/college-savings-calculator',
    icon: <DollarIcon />
  },
  {
    title: '529 Comparison Tool',
    description: 'Customize your 529 plan comparison by selecting the features that matter most to you.',
    url: '/compare-529-plans',
    icon: <ContributionIcon />
  },
  {
    title: 'Financial Aid Calculator',
    description: 'Estimate your child’s financial aid eligibility based on family income and assets.',
    url: '/calculators/financial-aid-calculator/',
    icon: <StarsIcon />
  },
  {
    title: 'Find 529 Eligible Institutions',
    description: 'See if a particular U.S. or international school qualifies for 529 plans and Coverdell ESAs.',
    url: '/eligible_institutions/',
    icon: <DollarIcon />
  },
  {
    title: '529 Savings vs. Loans Calculator',
    description: 'See how much less you’ll need to save with a 529 plan compared to using student loans for college.',
    url: '/529-savings-vs-loans-calculator/',
    icon: <CapIcon />
  },
  {
    title: '529 Superfunding Calculator',
    description: 'Determine the benefits of making a large contribution to a 529 plan under the 5-year election rule.',
    url: '/superfunding-calculator/',
    icon: <PrepaidIcon />,
    isPro: true
  },
  {
    title: '529 Pro Evaluator',
    description: 'The most comprehensive tool on the market for comparing 529 plans head-to-head.',
    icon: <PigIcon />,
    url: '/pro-529-evaluator/',
    isPro: true
  },
  {
    title: '529 State Tax Calculator',
    description: 'Find out the value of each state’s 529 tax benefits in gross dollars and their annualized return equivalent.',
    icon: <ThumbsUpIcon />,
    url: '/state_tax_529_calculator/',
    isPro: true
  }
]

const ToolCard = ({ tool: { title, description, url, icon, isPro } }: { tool: Tool }) => {
  return (
    <Link variant="content" className="block h-[260px] w-[260px]" href={url}>
      <div className="relative flex flex-col gap-y-[10px] h-full w-full bg-white-500 rounded-[10px] p-[20px]">
        <span className="text-blue-500">{icon}</span>
        <h3 className="text-body-main-bold">{title}</h3>
        <p className="text-body-caption">{description}</p>
        {isPro && (
          <span className="absolute top-[20px] right-[20px] text-body-caption text-white-500 justify-center px-[10px] py-[4px] bg-blue-500 rounded-[7px]">
            PRO
          </span>
        )}
      </div>
    </Link>
  )
}

export const ToolsSection = () => {
  return (
    <section className="bg-blue-50 pt-[70px]">
      <FullWidthContainer>
        <div className="mb-[15px] flex items-center">
          <h2 className="text-title-md small-desktop:text-title-lg-desktop">
            Popular tools and calculators
          </h2>
        </div>
        <p className="mb-[20px] tablet:mb-[40px] max-w-[550px]">
          Explore our range of consumer and professional tools to simplify your college savings
          journey.
        </p>
        <div className="hidden tablet:flex mb-[30px] flex-wrap gap-[20px]">
          {tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </FullWidthContainer>
      <div className="tablet:hidden overflow-x-hidden mb-[20px]">
        <Carousel className="w-full max-w-full">
          <CarouselContent>
            {tools.map((tool, index) => (
              <CarouselItem
                key={index}
                className={`py-[20px] pl-[20px] ${index === tools.length - 1 ? 'pr-[20px]' : ''}`}
              >
                <ToolCard tool={tool} />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
      <FullWidthContainer>
        <LinkButton
          href="/tools-calculators"
          className="w-full max-w-[400px] small-desktop:max-w-[260px]"
          variant="outline"
          size="lg"
        >
          See all tools
        </LinkButton>
      </FullWidthContainer>
    </section>
  )
}
