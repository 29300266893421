export default function HighlightRightIcon() {
  return (
    <svg height="100%" viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.91113 22.6172L28.6381 22.4894"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.50195 38.2539L12.3368 42.5332"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3369 2.57349L2.50204 6.8528"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
