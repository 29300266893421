import { useEffect, useState, useRef } from 'react'
import { Link } from '@components/ui/link'
import { Button } from '@components/ui/button'
import FullWidthContainer from '@components/layout/full-width-container'
import { US_STATES, US_STATES_MAP, type USState } from '@lib/us-states'
import { ResponsiveStateCombobox } from '@components/responsive-state-combobox'
import { svgMapStateShapes, svgMapStateCodes } from './svg-map-paths'
import { MobileMapBackground } from './mobile-map-background'
import styles from './us-map.module.css'

const DEFAULT_STATE_URL = '/529-plan-details'

export const USMap = ({ userStateId }: { userStateId: string | undefined }) => {
  const stateAbbrToUrl = makeStateAbbrToUrl(US_STATES)

  useEffect(() => {
    const paths = document.querySelectorAll(`.${styles.svgMapState}`)
    const textPaths = document.querySelectorAll(`.${styles.svgMapText} path`)

    paths.forEach((path) => {
      path.addEventListener('mouseenter', () => {
        const stateCode = path.getAttribute('data-statecode')
        textPaths.forEach((textPath) => {
          if (
            stateCode &&
            (textPath as SVGElement).dataset &&
            (textPath as SVGElement).dataset.statecode === stateCode
          ) {
            textPath.classList.add(styles.whiteText)
          }
        })
      })

      path.addEventListener('mouseleave', () => {
        textPaths.forEach((textPath) => {
          textPath.classList.remove(styles.whiteText)
        })
      })
    })

    // Cleanup function to remove event listeners
    return () => {
      paths.forEach((path) => {
        path.removeEventListener('mouseenter', () => { })
        path.removeEventListener('mouseleave', () => { })
      })
    }
  }, [])

  return (
    <>
      <DesktopUSMap stateAbbrToUrl={stateAbbrToUrl} />
      <MobileUSMap stateAbbrToUrl={stateAbbrToUrl} userStateId={userStateId} />
    </>
  )
}

function makeStateAbbrToUrl(states: readonly USState[]) {
  return states.reduce((acc, state) => {
    acc.set(state.abbr, `/529-plans/${state.slug}`)
    return acc
  }, new Map<string, string>())
}

const DesktopUSMap = ({ stateAbbrToUrl }: { stateAbbrToUrl: Map<string, string> }) => {
  return (
    <section className="hidden small-desktop:inline-block">
      <FullWidthContainer>
        <div className="flex flex-col mt-[80px] mb-[35px] gap-[15px]">
          <h2 className="text-title-lg-desktop">
            Find a 529 plan in your state
          </h2>
          <p className="text-body-lead">
            Select a state on the map to see available plans:
          </p>
        </div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1041 723"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin"
          className={styles.svgMap}
        >
          <g className={styles.svgMapStates}>
            {svgMapStateShapes.map((path, index) => (
              <Link key={path.stateCode} href={stateAbbrToUrl.get(path.stateCode) || DEFAULT_STATE_URL}>
                <path
                  className={styles.svgMapState}
                  key={index}
                  data-statecode={path.stateCode}
                  data-statename={path.stateName}
                  d={path.d}
                />
              </Link>
            ))}
          </g>

          <g className={styles.svgMapText}>
            {svgMapStateCodes.map((state, index) => (
              <path
                key={index}
                data-statecode={state.stateCode}
                d={state.d}
                className={styles.svgMapTextPath}
              />
            ))}
          </g>
        </svg>
      </FullWidthContainer>
    </section>
  )
}

type MobileUSMapProps = {
  stateAbbrToUrl: Map<string, string>
  userStateId: string | undefined
}

const MobileUSMap = ({ stateAbbrToUrl, userStateId }: MobileUSMapProps) => {
  const userState = userStateId ? US_STATES_MAP[userStateId] : 'ALL'
  const [state, setState] = useState<USState | 'ALL'>('ALL')
  const triggerButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (state === 'ALL') {
      setState(userState)
    }
  }, [userState])

  const handleViewPlans = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (state === 'ALL') {
      event.preventDefault();
      triggerButtonRef.current?.click();
    } else {
      const url = stateAbbrToUrl.get(state.abbr) || DEFAULT_STATE_URL;
      window.location.href = url;
    }
  };

  return (
    <section className="small-desktop:hidden">
      <FullWidthContainer>
        <div className="flex flex-col my-[60px] gap-[10px]">
          <h2 className="text-title-md">
            Find a 529 plan in your state
          </h2>
          <p className="text-body-main">
            Select a state on the map to see available plans:
          </p>
          <div className="flex place-items-center">
            <MobileMapBackground />
            <div className="absolute left-[40px] right-[40px]">
              <ResponsiveStateCombobox
                setState={setState}
                state={state}
                triggerButtonClassName="text-body-main-bold bg-blue-50"
                triggerButtonRef={triggerButtonRef}
                includeAllStatesOption={false}
              />
              <Button
                className="w-full mt-[10px]"
                size="lg"
                onClick={handleViewPlans}
              >
                View 529 plans
              </Button>
            </div>
          </div>
        </div>
      </FullWidthContainer>
    </section>
  )
}
